'use client';

import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';

import CompactLayout from 'src/layouts/compact';

import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function NotFoundView() {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            페이지를 찾을 수 없습니다!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            URL을 올바르게 입력하셨나요?
            <br />
            혹시 문제가 있으시다면, <a href="mailto:yeongbin.jo@pylab.co">관리자</a>에게 문의주세요.
            🙂
          </Typography>
        </m.div>

        <Button
          component={RouterLink}
          href="/"
          size="large"
          variant="contained"
          sx={{
            mt: 10,
          }}
        >
          홈으로 돌아가기
        </Button>
      </MotionContainer>
    </CompactLayout>
  );
}
